<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editMarket') }}</h5>
                <Accordion :activeIndex="0">
                    <AccordionTab :header="$t('markets.titles.marketDetails')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.canBeMixed') }}</label>
                                    <div class="p-col-12 p-md-2">
                                        <InputSwitch v-model="market.canBeMixed" />
                                    </div>
                                </div>
                                <div class="p-field p-grid" v-show="this.isEdit()">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.marketId" disabled />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.marketName" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayName') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.displayName" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.sortNo" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayColumns') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.displayCols" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <Fieldset :legend="$t('markets.namingRules.title')" :toggleable="true">
                                    <div class="p-grid">
                                        <div class="p-col-2">{X}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule1') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{!X}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule2') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{+X}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule3') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{-X}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule4') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{(X+/-c)}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule5') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{$competitorN}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule6') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{$event}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule7') }}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-2">{%player}</div>
                                        <div class="p-col-10">{{ $t('markets.namingRules.rule8') }}</div>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.marketGroups')">
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-col-6 p-md-2" v-for="(group, index) in groups" :key="group.name + '_' + index">
                                    <div class="p-field-checkbox">
                                        <Checkbox :id="'checkOption' + index" :value="group.name" v-model="selectedGroups" />
                                        <label :for="'checkOption' + index">{{ group.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.marketOutcomes')">
                        <div class="p-col-12" v-show="market.outcomes != null">
                            <div class="card p-grid">
                                <div v-for="(outcome, index) in market.outcomes" :key="market.id + '_' + index + '_' + outcome.id" class="p-col-12 p-grid">
                                    <div class="p-field p-grid p-col-12 p-md-2">
                                        <label for="outcomeId" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.id" disabled />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.name" disabled />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayName') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.displayName" />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-2">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.outcomeKey') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.code" />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-2">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.sortNo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.marketSpecifiers')">
                        <div class="p-col-12" v-show="market.specifiers != null">
                            <div class="card">
                                <div v-for="(specifier, index) in market.specifiers" :key="specifier.name + '_' + index" class="p-col-12 p-grid">
                                    <div class="p-field p-grid p-col-12 p-md-2">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.specifierName') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="specifier.name" disabled />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.specifierType') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="specifier.type" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <div class="p-col-12">
                    <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            market: {},
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'regularMarkets',
            errorKeyCount: 0,
            selectedGroups: [],
            showInfo: true,
            groups: [],
        };
    },
    mounted() {
        this.SportsSettingsApi.getAllGroups()
            .then((responseGroups) => {
                this.groups = responseGroups.data;
                this.SportsSettingsApi.getMarket(this.$route.params.id).then((response) => {
                    this.market = response.data;
                    for (var i = 0; i < this.groups.length; i++) {
                        if (this.market.groups.indexOf(this.groups[i].name) >= 0) {
                            this.selectedGroups.push(this.groups[i].name);
                        }
                    }
                });
            })
            .catch((error) => {
                this.errorText = error;
                this.showError = true;
                this.errorKeyCount++;
            });
    },
    methods: {
        submitForm() {
            this.disableSubmit = true;
            this.errorText = '';
            this.market.groups = this.selectedGroups;
            this.SportsSettingsApi.updateMarket(this.market)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.disableSubmit = false;
                    this.errorText = error;
                    this.showError = true;
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        getOutcomeClass(displayCols) {
            return 'p-mb-2 p-col-12 p-md-' + 12 / displayCols;
        },
    },
};
</script>
